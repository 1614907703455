import { useQuery } from "@tanstack/react-query";
import { getLowestInventoryLevelV1ShopifyShopSystemShopIdSystemInventoryLowestPostOptions } from "api/client/@tanstack/react-query.gen";
import avatar2 from "assets/images/users/avatar-2.png";

type Props = {
  system_shop_id: string;
};

function LowestInventory({ system_shop_id: selectedStoreId }: Props) {
  const { data: lowestInventoryData } = useQuery({
    ...getLowestInventoryLevelV1ShopifyShopSystemShopIdSystemInventoryLowestPostOptions(
      {
        path: { system_shop_id: selectedStoreId ?? "" },
        body: {
          available: [0, 10],
        },
      }
    ),
    enabled: !!selectedStoreId,
    select: (data) => data?.data,
  });

  return (
    <div className="order-last col-span-12 card lg:col-span-6 2xl:col-span-3">
      <div className="card-body">
        <h6 className="grow text-15 mb-4">Lowest stock product inventory</h6>
        <ul className="divide-y divide-slate-200 dark:divide-zink-500">
          {lowestInventoryData?.map((item, index) => {
            return (
              <li
                className="flex items-center gap-3 py-2 first:pt-0 last:pb-0"
                key={item._id}
              >
                <div className="size-8 rounded-full shrink-0 bg-slate-100 dark:bg-zink-600">
                  <img
                    src={
                      (item.variant?.product as any)?.images?.edges?.[0]?.node
                        .src ?? avatar2
                    }
                    alt=""
                    className="size-8 rounded-full"
                  />
                </div>
                <div className="grow">
                  <h6 className="font-medium">
                    {(item?.variant?.product as { title: string })?.title}
                  </h6>
                  {/* <p className="text-slate-500 dark:text-zink-200">
                  arthur@tailwick.com
                </p> */}
                </div>
                <div className="shrink-0">
                  <h6>{item?.available}</h6>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default LowestInventory;
