import TableContainer from "Common/TableContainer";
import React, { useMemo } from "react";
import { ShopifyAnalyticProductAggregate } from "api/client";
import { Search } from "lucide-react";

type ProductStatisticsProps = {
  data?: ShopifyAnalyticProductAggregate[] | any;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currency?: string;
};

const ProductsStatistics = ({
  data,
  onSearch,
  currency,
}: ProductStatisticsProps) => {
  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Image",
        accessorKey: "image",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <img
            src={
              cell.row.original?.variants?.[0]?.image?.src ??
              cell.row.original?.image?.src ??
              "https://placehold.co/400"
            }
            className="w-10 h-10"
            alt={cell.getValue()?.id}
          />
        ),
      },
      {
        header: "Product type",
        accessorKey: "product_type",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Total quantity sold",
        accessorKey: "total_quantity_sold",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Tags",
        accessorKey: "tags",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Vendor",
        accessorKey: "vendor",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Available quantity",
        accessorKey: "available_quantity",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Price",
        accessorKey: "variants",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) =>
          cell.getValue()?.[0]?.price +
          " " +
          (cell.getValue()?.[0]?.currency ?? currency ?? ""),
      },
    ],
    [currency]
  );
  return (
    <React.Fragment>
      <div className="order-11 col-span-12 2xl:order-1 card 2xl:col-span-12">
        <div className="card-body">
          <div className="grid items-center grid-cols-1 gap-3 mb-5 xl:grid-cols-12">
            <div className="xl:col-span-3">
              <h6 className="text-15">Top Selling Product</h6>
            </div>
            <div className="xl:col-span-3 xl:col-start-10">
              <div className="flex gap-3">
                <div className="relative grow">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Search for ..."
                    autoComplete="off"
                    onChange={(e) => onSearch?.(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"></Search>
                </div>
                {/* <button
                  type="button"
                  className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                >
                  <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>{" "}
                  Export
                </button> */}
              </div>
            </div>
          </div>
          <TableContainer
            isPagination={false}
            columns={columns || []}
            data={data || []}
            divclassName="-mx-5 overflow-x-auto"
            tableclassName="w-full whitespace-nowrap"
            theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
            thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500 w-10"
            tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
            PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductsStatistics;
