import React from "react";
import {
  Cog,
  Coins,
  Kanban,
  ListFilter,
  ShoppingBag,
  TicketX,
  Users,
} from "lucide-react";

import { ShopifyAnalyticTotalPriceAggregate } from "api/http";
import {
  ShopifyAnalyticAverageOrderValueAggregate,
  ShopifyAnalyticRevenueCurrentPeriodVsPreviousPeriodAggregate,
  ShopifyOrderModelDTO,
} from "api/client";
import CountUp from "react-countup";

export type WidgetsProps = {
  total?: {
    customer?: {
      madePurchase?: number;
      total: number;
    };
    revenue?: {
      total?: ShopifyAnalyticTotalPriceAggregate;
      currentPeriod?: ShopifyAnalyticRevenueCurrentPeriodVsPreviousPeriodAggregate;
      previousPeriod?: ShopifyAnalyticRevenueCurrentPeriodVsPreviousPeriodAggregate;
    };
    averageOrder?: ShopifyAnalyticAverageOrderValueAggregate;
    bounceRate?: number;
    refunds?: ShopifyOrderModelDTO;
  };
};

const Widgets = (props: WidgetsProps) => {
  return (
    <React.Fragment>
      <div className="order-1 col-span-3  bg-green-100 dark:bg-green-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-green-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <Users />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.customer?.total ?? 0}
              className="counter-value"
            />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Customer</p>
        </div>
      </div>
      <div className="order-1 col-span-3  bg-yellow-100 dark:bg-yellow-400-200/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-yellow-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-yellow-200/50 dark:text-yellow-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-yellow-600 rounded-md text-15 text-yellow-50">
            <ShoppingBag />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.customer?.madePurchase ?? 0}
              className="counter-value"
            />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Total Customer Made Purchase
          </p>
        </div>
      </div>
      <div className="order-1 col-span-3  bg-lime-300-100 dark:bg-lime-300-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-orange-500/20 relative overflow-hidden">
        <div className="card-body">
          <ListFilter className="absolute top-0 size-32 stroke-1 text-orange-200/50 dark:text-orange-500/20 ltr:-right-10 rtl:-left-10"></ListFilter>
          <div className="flex items-center justify-center size-12 bg-orange-500 rounded-md text-15 text-orange-50">
            <Cog />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.revenue?.currentPeriod?.revenue ?? 0}
              decimals={2}
            />{" "}
            {props?.total?.revenue?.currentPeriod?.currency}
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Current period revenue
          </p>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.revenue?.previousPeriod?.revenue ?? 0}
              decimals={2}
            />{" "}
            {props?.total?.revenue?.currentPeriod?.currency}
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Previous period revenue
          </p>
        </div>
      </div>

      <div className="order-1 col-span-3  bg-orange-100 dark:bg-orange-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-orange-500/20 relative overflow-hidden">
        <div className="card-body">
          <ListFilter className="absolute top-0 size-32 stroke-1 text-orange-200/50 dark:text-orange-500/20 ltr:-right-10 rtl:-left-10"></ListFilter>
          <div className="flex items-center justify-center size-12 bg-lime-300-500 rounded-md text-15 text-bg-lime-300-50">
            <Cog />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.revenue?.total?.total_price_sum ?? 0}
              decimals={2}
            />{" "}
            {props?.total?.revenue?.total?.currency}
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Order Revenue</p>
        </div>
      </div>

      <div className="order-1 col-span-3  bg-sky-100 dark:bg-sky-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-sky-500/20 relative overflow-hidden">
        <div className="card-body">
          <ListFilter className="absolute top-0 size-32 stroke-1 text-sky-200/50 dark:text-sky-500/20 ltr:-right-10 rtl:-left-10"></ListFilter>
          <div className="flex items-center justify-center size-12 rounded-md bg-sky-500 text-15 text-sky-50">
            <Coins />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              className="counter-value"
              end={props?.total?.averageOrder?.average_order_value ?? 0}
              decimals={2}
              duration={3}
            />{" "}
            {props?.total?.averageOrder?.currency ?? 0}
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Avg. Order</p>
        </div>
      </div>
      <div className="order-1 col-span-3  bg-purple-100 dark:bg-purple-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-purple-200/50 dark:text-purple-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-purple-500 rounded-md text-15 text-purple-50">
            <Users />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.averageOrder?.order_count ?? 0}
              className="counter-value"
            />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Order</p>
        </div>
      </div>
      <div className="order-1 col-span-3  bg-red-100 dark:bg-red-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-red-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-red-200/50 dark:text-red-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-red-500 rounded-md text-15 text-purple-50">
            <TicketX />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={props?.total?.refunds?.total_refunded ?? 0}
              className="counter-value"
            />
          </h5>{" "}
          {props?.total?.refunds?.currency}
          <p className="text-slate-500 dark:text-slate-200">Total refund</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Widgets;
